// This file is all of the global styles that wouldn't be apart of the reset,
// but are specific to this app.

@import "styles/_colors.module.scss";
@import "styles/_fonts.module.scss";
@import "styles/_reset.scss";

body {
    color: $color-text-primary;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

button {
    font-size: $font-size-300;
}
