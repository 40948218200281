@import "styles/_shared.scss";

.FormCardContainer {
    background-color: $color-background-dark;
    border-radius: $border-radius-large;
    box-shadow: 0 4px 6px transparentize($color: $color-primary-600, $amount: 0.7);
    display: flex;
    flex-direction: column;
    padding: $size-300;
    width: 100%;

    .FormCardContainer-top-row,
    .FormCardContainer-bottom-row {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .FormCardContainer-bottom-row {
        margin-top: $size-300;
    }

    .FormCardContainer-close-button {
        @include svg-icon-color($color-light);

        margin-left: $size-100;

        @include media-query-small {
            margin-left: $size-200;
        }
    }

    .LabelledInput-Label--error {
        // Because of the dark background of the card, we need error messages to be a lighter color
        // to have the necessary contrast to stand out.
        //
        // Want to do this in this component (even though it's a container) just so we don't have to
        // do it in every sub-component.
        color: $color-negative-300 !important;
    }
}
