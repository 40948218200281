@import "styles/_shared.scss";

.ImportRulesTableRow {
    @include import-rules-table-grid();

    .ImportRulesTableRow-date {
        grid-area: date;
        text-align: right;
    }

    .ImportRulesTableRow-rule {
        display: grid;
        grid-area: rule;
        grid-template-columns: 1fr 1fr;

        .ImportRulesTableRow-rule-conditions,
        .ImportRulesTableRow-rule-actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .ImportRulesTableRow-rule-actions-container {
            align-items: center;
            display: flex;

            svg {
                @include svg-icon-color($color-neutral-400);

                margin-right: $size-500;
            }
        }
    }
}
