@import "styles/_shared.scss";

.StepNavigationFooter {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: $size-600;
    width: 100%;

    @include media-query-navigation-change {
        margin-top: $size-800;
        max-width: $size-transactions-import-step-width;
    }

    .StepNavigationFooter-body {
        display: flex;
        justify-content: center;
        margin-top: $size-500;

        .StepNavigationButtons {
            margin-left: auto;
        }
    }
}
