@import "styles/_shared.scss";

#app {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @include supports-safe-area-insets {
        // Need padding to account for notched devices.
        padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
            env(safe-area-inset-left);
    }
}
