@import "styles/_shared.scss";

.RuleActionCard {
    .SelectInput {
        padding: 0 $size-200;
    }

    .RuleActionCard-TopRow,
    .RuleActionCard-BottomRow {
        align-items: center;
        display: grid;
        gap: $size-300;
        grid-auto-flow: column;
        width: 100%;

        p {
            color: $color-neutral-200;
        }
    }
}
