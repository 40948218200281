@import "styles/_shared.scss";

$form-spacing: $size-500;

.PasswordResetForm {
    gap: $form-spacing;
    max-width: $size-auth-form-max-width;
    width: 100%;

    .PasswordResetForm-form {
        display: grid;
        gap: $form-spacing;
    }
}
