@import "styles/_shared.scss";

.SubmitButton-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;

    .SubmitButton-TextField-error {
        @include micro-transition(margin-bottom, opacity);

        align-self: flex-start;
        color: $color-negative-text;
        font-weight: $font-weight-bold;
        opacity: 0;

        &.SubmitButton-TextField-error--visible {
            margin-bottom: $size-200;
            opacity: 1;
        }
    }

    .SubmitButton {
        // We don't want to transition border-radius when the --loading class is removed,
        // because it looks better when the button immediately grows back with square corners.
        @include micro-transition(background, width);

        &.SubmitButton--loading {
            // Make the outline circular, like the button now is.
            @include circular-custom-outline();

            // Using include-width makes the button transform into a square
            // (which is then a circle after the border radius changes),
            // to match the shape of the loading spinner.
            @include button-height($include-width: true);

            @include micro-transition(background, border-radius, width);

            // Transform the button into the same shape as the LoadingSpinner.
            // This means no background and a thicker, circular border.
            background: none;
            border: $border-loading-spinner-width solid $color-neutral-400;
            border-radius: 50%;

            // Hide the button's regular drop shadow while loading.
            box-shadow: none;

            &::before {
                // Hide the hover drop shadow while loading.
                //
                // Need !important to make it override the focus style for applying
                // the shadow. But somehow, it only needs !important in Firefox;
                // it works fine in Chrome.
                opacity: 0 !important;
            }
        }

        &.SubmitButton--error {
            // Shake the SubmitButton when an error occurs.
            animation: error-shake 0.3s 1 ease-in-out;
        }
    }
}

.SubmitButton--story-default-instructions {
    margin-bottom: $size-500;
}
