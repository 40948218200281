@import "styles/_shared.scss";

$form-spacing: $size-400;

.SendPasswordResetForm {
    gap: $form-spacing;
    max-width: $size-auth-form-max-width;
    width: 100%;

    .SendPasswordResetForm-form {
        display: grid;
        gap: $form-spacing;
    }
}
