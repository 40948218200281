@import "styles/_shared.scss";

.SummaryStep {
    width: 100%;

    .SummaryStep-body-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: $size-600;

        .SummaryStep-body {
            display: grid;
            gap: $size-500;
            margin-bottom: $size-400;
            max-width: $size-transactions-import-step-width;
            width: 100%;

            h3 {
                font-weight: $font-weight-bold;
            }
        }

        .SummaryStep-PaginationFooter {
            .Divider {
                // Because we have the Divider from the StepNavigationFooter,
                // we don't need the Divider provided by the PaginationFooter.
                display: none;
            }
        }
    }
}

.SummaryStats {
    // Just re-purposing the list item shadow since it's nice and subtle.
    @include list-item-shadow();

    background-color: $color-background-light;
    border-radius: $border-radius-normal;
    display: grid;
    gap: $size-500 $size-300;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding: $size-400;

    .SummaryStats-stat-header {
        color: $color-text-secondary;
        font-size: $font-size-200;
    }

    .SummaryStats-stat {
        margin-top: $size-200;

        &.SummaryStats-stat--positive {
            color: $color-positive-500;
            font-weight: $font-weight-bold;
        }

        &.SummaryStats-stat--negative {
            color: $color-negative-500;
            font-weight: $font-weight-bold;
        }

        &.SummaryStats-stat-transactions {
            color: $color-primary-600;
            font-weight: $font-weight-bold;
        }
    }
}
